import styled from 'styled-components';
import { media } from '../../utils';

export const MenuStyles = styled.div``;

export const HamburgerWrapper = styled.button`
	padding: 0;

	${media.medium`
			display: none;
		`}
`;
