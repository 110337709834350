import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	width: fit-content;
	max-width: 220px;
`;

export const Text = styled.span`
	display: flex;
`;
