import React from 'react';
import { Wrapper } from './Neon.styled';

export default function Neon({ as, color, children, center }) {
	return (
		<Wrapper as={as} color={color} center={center}>
			{children}
		</Wrapper>
	);
}
