import React from 'react';
import { Content, Description, FeaturedImg, Wrapper } from './Bio.styled';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Neon } from '../Neon';

export default function Bio({ data }) {
	const { title, description } = data.site.siteMetadata;
	const image = getImage(data.file.childImageSharp.gatsbyImageData);

	return (
		<Wrapper>
			<Neon as="h1" color="blue">
				About
			</Neon>
			<Content>
				<FeaturedImg>
					<GatsbyImage image={image} alt={title} />
				</FeaturedImg>
				<Description>{description}</Description>
			</Content>
		</Wrapper>
	);
}
