import React from 'react';

export default function Hamburger({
	color,
	rotate,
	className,
	menuClicked,
	borderRadius,
	isOpen = false,
	strokeWidth = 2,
	width = 36 + 'px',
	height = 30 + 'px',
	animationDuration = '0.4',
	halfStrokeWidth = '-' + strokeWidth / 2 + 'px',
	halfHeight = parseInt(height.replace('px', '')) / 2 + 'px',
}) {
	const getTransformValue = (isOpen, defaultPos, rotateVal) => {
		return (
			'translate3d(0,' +
			(isOpen ? halfHeight : defaultPos) +
			',0) rotate(' +
			(isOpen ? rotateVal + 'deg' : '0') +
			')'
		);
	};

	const styles = {
		container: {
			width: width,
			height: height,
			position: 'relative',
			transform: 'rotate(' + (rotate || 0) + 'deg)',
			cursor: 'pointer',
		},
		lineBase: {
			display: 'block',
			height: strokeWidth + 'px',
			width: '100%',
			background: color || '#000',
			transitionTimingFunction: 'ease',
			transitionDuration: animationDuration + 's',
			borderRadius: (borderRadius || 0) + 'px',
			transformOrigin: 'center',
			position: 'absolute',
		},
		firstLine: {
			transform: getTransformValue(isOpen, 0, 45),
			marginTop: halfStrokeWidth,
		},
		secondLine: {
			transitionTimingFunction: 'ease-out',
			transitionDuration: animationDuration / 4 + 's',
			opacity: isOpen ? '0' : '1',
			top: halfHeight,
			marginTop: halfStrokeWidth,
		},
		thirdLine: {
			transform: getTransformValue(isOpen, height, -45),
			marginTop: halfStrokeWidth,
		},
	};

	return (
		<div
			style={styles.container}
			className={className}
			onClick={menuClicked}
			role="presentation"
		>
			<span style={{ ...styles.firstLine, ...styles.lineBase }} />
			<span style={{ ...styles.secondLine, ...styles.lineBase }} />
			<span style={{ ...styles.thirdLine, ...styles.lineBase }} />
		</div>
	);
}
