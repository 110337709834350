import styled from 'styled-components';
import { media } from '../../utils';

export const Wrapper = styled.div`
	h1 {
		text-align: center;
	}
`;

export const List = styled.ul`
	list-style: none;
	padding: 0;
	text-align: center;
	display: grid;
	gap: var(--gap);
	grid-auto-rows: 1fr;

	${media.small`	
		grid-template-columns: repeat(2, 1fr);
	`}

	${media.medium`	
		grid-template-columns: repeat(3, 1fr);
		
	`}

	h3 {
		text-align: center;
		margin: 20px auto 0px;
		font-weight: 500;
		font-size: larger;
	}

	img {
		overflow: hidden;
		display: block;
		border-radius: var(--borderRadius);
	}

	time {
		color: var(--gray);
	}

	.gatsby-image-wrapper {
		min-height: 190px;
	}
`;
