import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Neon } from '../Neon';
import { List, Wrapper } from './Tiles.styled';

export default function Tiles({ data, title, color }) {
	return (
		<Wrapper>
			{title && (
				<Neon as="h1" color={color}>
					{title}
				</Neon>
			)}
			{data && (
				<List>
					{data.map(
						item =>
							item.frontmatter.status === 'public' && (
								<li key={item.id}>
									<Link
										to={`/projects/${item.frontmatter.slug}`}
									>
										<GatsbyImage
											image={getImage(
												item.frontmatter.thumb
													.childImageSharp
													.gatsbyImageData
											)}
											alt={item.frontmatter.title}
										/>
										<h3>{item.frontmatter.title}</h3>
										<time>{item.frontmatter.date}</time>
									</Link>
								</li>
							)
					)}
				</List>
			)}
		</Wrapper>
	);
}
